import React, { useEffect, useState, useContext } from "react";
import Navbar from "../Navbar/Navbar";
import { UserContext } from "../../contexts/UserContext";

import TradingPlatformIframe from "../TradingPlatform/TradingPlatformIframe";
import Settings from "../Settings/Settings";
import { Routes, Route, useLocation } from "react-router-dom";
import WelcomeModal from "../Modals/WelcomeModal";
import ClientIBDashboard from "./ClientDashboard/ClientIBManager/ClientIBDashboard";
import SpainGeoblockModal from "../Modals/SpainGeoblockModal";

function Dashboard() {
  const location = useLocation(); // Get location object to access navigation state
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalData, setModalData] = useState({}); // State to hold data to be shown in the modal
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Check if there's any state passed through navigation
    if (location.state && location.state.username && location.state.password) {
      setShowModal(true); // Show the modal
      setModalData({
        username: location.state.username,
        password: location.state.password,
      }); // Set the modal data
    }
  }, [location.state]);

  // Function to check if the user's country is Spain (case insensitive)
  const isUserInSpain = (user) => {
    return user.userData.country.toLowerCase() === "spain";
  };

  return (
    <div>
      <Navbar />
      <WelcomeModal show={showModal} data={modalData} />
      {isUserInSpain(user) && <SpainGeoblockModal />}

      <Routes>
        <Route path="/" element={<TradingPlatformIframe />} />
        <Route path="settings" element={<Settings />} />
        <Route path="referrals" element={<ClientIBDashboard />} />
      </Routes>
    </div>
  );
}

export default Dashboard;
