import {
  Fragment,
  useRef,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserContext } from "../../contexts/UserContext.js";
import { useNotification } from "../../contexts/NotificationContext.js";
import { functions, auth } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
import { useLanguage } from "../../contexts/LanguageContext.js";
import PraxisCashierIframe from "./PSPModals/PraxisCashierIframe.js";
import PraxisCashierIframeTest from "./PSPModals/PraxisCashierIframeTest.js";
import KYCInfoModal from "./KYCModals/KYCInfoModal.js";

export default function DepositModal({ open, setOpen, onCloseModal }) {
  const cancelButtonRef = useRef(null);
  const { showNotification } = useNotification(); // Use the notification hook
  const { user } = useContext(UserContext);
  const { language } = useLanguage();
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [pspType, setPspType] = useState(null);
  const [isCashierLoaded, setIsCashierLoaded] = useState(false);
  const [showKYCInfo, setShowKYCInfo] = useState(false);

  const handleDeposit = useCallback(async () => {
    if (
      user.userData.kycStatus !== "approved_kyc" &&
      !user.userData.allowDepositBeforeKYC
    ) {
      setShowKYCInfo(true);
      return;
    }
    try {
      setIsLoading(true);

      // Define the callable function from Firebase
      const routePSPRequest = httpsCallable(functions, "routePSPRequest");

      // Collect required data
      const requestData = {
        uid: auth.currentUser.uid,
        brokerUID: user.userData.brokerUID,
        userLogin: selectedAccountId,
        language: language,
      };

      console.log(requestData);

      // Make the call
      const response = await routePSPRequest(requestData);

      console.log("Firebase function response:", response.data);

      // Check for redirect_url and update the state
      if (response.data) {
        setRedirectUrl(response.data.redirect_url);
        setAuthToken(response.data.auth_token);
        setPspType(response.data.type);
      }
    } catch (error) {
      console.error("Error initiating deposit:", error);
      showNotification("error", "Failed to initiate deposit.");
    } finally {
      setIsLoading(false);
    }
  }, [user, selectedAccountId, showNotification, language]); // Added necessary dependencies here

  useEffect(() => {
    // Set the selectedAccountId if modal is opened.
    // If there's only one trading account, it gets selected.
    // If there are multiple trading accounts, the first one gets preselected.
    if (
      open &&
      user &&
      user.accountData &&
      user.accountData.trading_accounts &&
      user.accountData.trading_accounts.length > 0
    ) {
      setSelectedAccountId(user.accountData.trading_accounts[0].id_account);
      console.log("Selected account:", user.accountData.trading_accounts[0]);
    }
  }, [open, user]);

  useEffect(() => {
    // Handle deposit if modal is opened and selectedAccountId is set.
    // Additional condition: Execute only if there's exactly one trading account.
    if (
      open &&
      selectedAccountId &&
      user.accountData.trading_accounts.length === 1
    ) {
      handleDeposit();
    }
  }, [open, selectedAccountId, handleDeposit, user]);

  const accounts =
    user && user.accountData && user.accountData.trading_accounts
      ? user.accountData.trading_accounts
      : [];

  useEffect(() => {
    if (!open) {
      setShowKYCInfo(false);
    }
  }, [open]);

  useEffect(() => {}, [open]);

  const closeAllModals = useCallback(() => {
    // Close the deposit modal
    setOpen(false);
    // Close the KYCInfo modal
    setShowKYCInfo(false);
    // ... any other modals you have or might add in the future

    // If there's any other cleanup you want to do on modal close, add it here
  }, [setOpen]);

  const handleTransactionApproved = (transactionData) => {
    const { amount, currency } = transactionData;
    showNotification(
      "success",
      `Success! Your transaction of ${amount} ${currency} has been approved!`
    );

    setOpen(false); // Close the modal
  };

  const handleTransactionAlert = (transactionData) => {
    const { status, error_message } = transactionData;
    const alertMessage = error_message || "Alert! Transaction not approved.";

    showNotification("warning", `Transaction ${status}: ${alertMessage}`);

    setOpen(false); // Close the modal
  };

  return showKYCInfo ? (
    <KYCInfoModal
      open={showKYCInfo}
      setOpen={setShowKYCInfo}
      onCloseModal={closeAllModals}
      // ... other props
    />
  ) : (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {isLoading ? (
                  <div className="flex items-center justify-center h-full p-36">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600 dark:fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : redirectUrl && pspType === "Praxis Cashier" ? (
                  <PraxisCashierIframe
                    redirectUrl={redirectUrl}
                    authToken={authToken}
                    onLoaded={() => setIsCashierLoaded(true)}
                    isHidden={!isCashierLoaded}
                    onTransactionApproved={handleTransactionApproved}
                    onTransactionAlert={handleTransactionAlert}
                  />
                ) : redirectUrl && pspType === "Praxis Cashier Test" ? (
                  <PraxisCashierIframeTest
                    redirectUrl={redirectUrl}
                    authToken={authToken}
                    onLoaded={() => setIsCashierLoaded(true)}
                    isHidden={!isCashierLoaded}
                    onTransactionApproved={handleTransactionApproved}
                    onTransactionAlert={handleTransactionAlert}
                  />
                ) : (
                  <>
                    <div className="bg-white dark:bg-gray-800 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                          >
                            Deposit to trading account
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Select a trading account below and click deposit.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <fieldset className="mt-2">
                          <legend className="sr-only">Account</legend>
                          <div className="divide-y divide-gray-200 dark:divide-gray-700">
                            {accounts
                              .filter(
                                (account) => account.accountType !== "Demo"
                              )
                              .map((account, accountIdx) => (
                                <div
                                  key={accountIdx}
                                  className="relative flex items-start pb-4 pt-3.5"
                                >
                                  <div className="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                      htmlFor={`account-${account.id_account}`}
                                      className="font-medium text-gray-900 dark:text-white"
                                    >
                                      Account ID: {account.login}{" "}
                                    </label>
                                    <p
                                      id={`account-${account.id_account}-description`}
                                      className="text-gray-500 dark:text-gray-400"
                                    >
                                      Currency: {account.currency}
                                    </p>
                                  </div>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      id={`account-${account.id_account}`}
                                      aria-describedby={`account-${account.id_account}-description`}
                                      name="account"
                                      type="radio"
                                      defaultChecked={
                                        account.id_account === selectedAccountId
                                      }
                                      onChange={() =>
                                        setSelectedAccountId(account.id_account)
                                      }
                                      className="h-4 w-4 border-gray-300 dark:border-gray-700 text-primary-600 focus:ring-primary-600"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                        onClick={handleDeposit}
                        disabled={isLoading} // Disable the button while loading
                      >
                        {isLoading ? (
                          <div className="flex items-center">
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Initiating deposit...
                          </div>
                        ) : (
                          "Deposit"
                        )}
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-white dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-white/20"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
